import Vue from 'vue';
import Component from 'vue-class-component';
import PageRender from '../../models/PageRender';
import { bookingService, dateHelper, bookerService, searchService, siteService, placeSearchHelper, loginService, loginHelper } from '@/main';
import to from 'await-to-js';
import { SearchPlaceTypeParamsInterface, BookingPlaceObject } from '../../models/Interfaces';
import Feature from '../../models/Feature';
import { SearchResponse } from '../../models/Search/Respone';
import SearchPlaceType from '../../models/Search/PlaceType';
import { Watch } from 'vue-property-decorator';
import Booking from '../../models/Booking';
import Booker from '../../models/Booker';
import moment from 'moment/min/moment.min.js';
import Place from '../../models/Place';
import { AxiosResponse } from 'axios';
import PlaceType from '../../models/PlaceType';
import { BModal } from 'bootstrap-vue';
import { FormWizard, TabContent, WizardButton, WizardStep } from 'vue-form-wizard/src';
import { required } from 'vuelidate/lib/validators';
import { IVuelidate, validationMixin } from 'vuelidate';
import SearchPrice from '@/models/Search/Price';
import { sitesModule } from '@/store/modules/site';

@Component({
    mixins: [validationMixin],
    components: {
        FormWizard,
        TabContent,
        WizardButton,
        WizardStep,
    },
    validations: {
        booking: {
            mainBooker: { required },
            places: { required },
        },
        period: {
            fromDate: { required },
            toDate: { required },
        },
        editBooker: {
            firstName: { required },
            lastName: { required },
            emailAddress: { required },
            phoneNumber: { required },
            address: { required },
            city: { required },
            houseNumber: { required },
            postalCode: { required },
            streetName: { required },
        },
    },
})
export default class CreateBookingPage extends PageRender implements IVuelidate<any> {
    public isLoading: boolean = true;
    public isCalculatingPrice: boolean = false;
    public isLoadingAvailablePlaceTypes: boolean = false;
    public places: Place[] = [];
    public placeTypes: PlaceType[] = [];
    public travelTypes: any[] = [];
    public period: any = { fromDate: null, toDate: null };
    public filterFeatures: Feature[];
    public historicBookings: Booking[] = [];
    public searchParams: SearchPlaceTypeParamsInterface = {
        site: null,
        siteOwner: loginHelper.getSiteOwner(),
        fromDate: null,
        toDate: null,
        capacity: 0,
        fromCapacity: 1,
        toCapacity: 2,
        facets: '',
        Senior: 0,
        Adult: 2,
        ChildThreeYearsAndUp: 0,
        ZeroUntilTwoYears: 0,
        Pet: 0,
        petsAllowed: false,
        disabledAccessible: false,
    };
    public searchResponse: SearchResponse = null;
    public availablePlaceTypes: SearchPlaceType[] = [];
    public selectedPlaceTypes: number[] = [];
    public booking: Booking = new Booking();
    public bookers: Booker[] = [];
    public editBooker: Booker = new Booker();
    public searchTimeout: any = null;
    public searchTimeoutTime: number = 250;
    public availableOptions = [];
    public newLicensePlate: string = '';
    public facets: any = {};
    public showPriceRules: boolean = false;
    public optionalPhysicalProducts: any = [];
    public optionalOtherProducts: any = [];
    public selectedOptionalProductIds: number[] = [];
    public invalid: boolean = false;

    public $refs!: {
        confirmContinueBooking: BModal;
        createMainBookerModal: BModal;
        wizard: any;
    };

    @Watch('period', { deep: true, immediate: true })
    public async watchPeriod(val, old) {
        this.searchParams.fromDate = dateHelper.format(this.period.fromDate, 'YYYY-MM-DD');
        this.searchParams.toDate = dateHelper.format(this.period.toDate, 'YYYY-MM-DD');

        this.booking.arrivalDate = this.searchParams.fromDate;
        this.booking.departureDate = this.searchParams.toDate;

        await this.searchAvailablePlaceTypes();
    }

    @Watch('travelTypes', { deep: true, immediate: true })
    public async watchTravelTypes(val, old) {
        this.booking.travelGroup = [];
        let totalAmount = 0;
        this.travelTypes.forEach((type) => {
            if (type.amount > 0) {
                this.booking.travelGroup.push({
                    amountOfTravellers: type.amount,
                    travelGroupType: type.type,
                });

                switch (type.type) {
                    case 1:
                        this.searchParams.ZeroUntilTwoYears = type.amount;
                        break;
                    case 2:
                        this.searchParams.ChildThreeYearsAndUp = type.amount;
                        break;
                    case 3:
                        this.searchParams.Adult = type.amount;
                        break;
                    case 4:
                        this.searchParams.Senior = type.amount;
                        break;
                    case 5:
                        this.searchParams.Pet = type.amount;
                        break;
                }
            }

            totalAmount += type.amount;
        });
    }

    public async mounted() {
        await sitesModule.fetchSitesIfNeeded();
        this.travelTypes = this.getTravelTypes();
        await this.getBookers();
        this.places = await this.getPlaces();
        this.placeTypes = await this.getPlaceTypes();
        this.searchParams.site = this.site.siteId;

        if (this.$route.query) {
            await this.loadQueryParams(this.$route.query);
            await this.$nextTick();
            this.searchAvailablePlaceTypes();
        }

        this.booking.arrivalDate = this.searchParams.fromDate;
        this.booking.departureDate = this.searchParams.toDate;
        this.booking.siteId = this.site.siteId;
        this.isLoading = false;
    }

    public get site() {
        return sitesModule.activeSite;
    }

    public getPlacesOfPlaceType(placeTypeId: number) {
        let options: any[] = [];
        if (this.searchResponse && this.searchResponse.accommodationTypes) {
            const placeType = this.searchResponse.accommodationTypes.find((type) => {
                return type.placeTypeId === placeTypeId;
            });

            if (placeType) {
                options = this.places.filter((place) => {
                    const existInBooking = this.booking.places.find((placeObject: BookingPlaceObject) => {
                        return placeObject.placeId === place.placeId;
                    });

                    return !existInBooking && placeType.placeIds.indexOf(place.placeId) > -1;
                });
            }
        }
        return options;
    }

    public getBookerLabel(booker: Booker) {
        const labelSuffix = booker.emailAddress ? ` (${booker.emailAddress})` : '';
        return `${booker.firstName} ${booker.insertion ? booker.insertion : ''} ${booker.lastName}${labelSuffix}`;
    }

    public decreaseTravelType(type) {
        if (type.amount > 0) {
            type.amount--;
        } else {
            type.amount = 0;
        }
    }

    public increaseTravelType(type) {
        type.amount++;
    }

    public decreaseOptionalProduct(product) {
        if (product.amount > 0) {
            product.amount--;
        } else {
            product.amount = 0;
        }
    }

    public increaseOptionalProduct(product) {
        product.amount++;
    }

    public async selectMainBooker(booker: Booker) {
        this.booking.mainBooker = booker;
        this.booking.mainBookerId = parseInt(booker.mainBookerId.toString(), 10);
        await this.getHistoricBookings();
    }

    public async getHistoricBookings() {
        const [bErr, bResponse] = await to(
            bookingService.getBookings(this.site.siteId, null, null, null, null, null, null, this.booking.mainBookerId),
        );
        if (bErr) {
            return this.showError('Historische boekingen ophalen mislukt');
        }

        this.historicBookings = bResponse.data;
    }

    public cancelCreateBooker() {
        this.editBooker = new Booker();
        this.$refs.createMainBookerModal.hide();
    }

    public async saveNewBooker() {
        if (!this.validateObject('editBooker')) {
            this.invalid = true;
            return this.clearAndShowWarning('Niet alle verplichte velden zijn ingevuld');
        }
        this.invalid = false;
        const self = this;
        this.showPending('Hoofdboeker aanmaken...');

        const [err, response] = await to(bookerService.createNewBooker(this.editBooker, this.site.siteId));
        if (err || !response) {
            this.clearAndShowError('Mislukt om een nieuwe hoofdboeker aan te maken.', err);
        } else {
            this.clearAndShowSuccess('Nieuwe hoofdboeker succesvol aangemaakt.');
            this.cancelCreateBooker();

            this.isLoading = true;

            this.isLoading = false;

            Vue.nextTick(() => {
                const newMainBooker = response.data;
                self.booking.mainBooker = newMainBooker;
                self.booking.mainBookerId = newMainBooker.mainBookerId;
            });
        }
    }

    public async searchAvailablePlaceTypes() {
        if (!placeSearchHelper.validateSearchParams(this.searchParams)) {
            return;
        }
        this.isLoadingAvailablePlaceTypes = true;
        const [err, response] = await to(searchService.accommodation(this.searchParams));
        this.isLoadingAvailablePlaceTypes = false;

        if (response && response.data) {
            this.searchResponse = response.data;
            this.availablePlaceTypes = this.searchResponse.accommodationTypes;
            this.clearNotifications();

            this.selectedPlaceTypes.forEach((placeTypeId: number) => {
                const isAvailable = this.availablePlaceTypes.find((pt) => pt.placeTypeId === placeTypeId);
                if (!isAvailable) {
                    const foundPlaceType = this.placeTypes.find((pt) => pt.placeTypeId === placeTypeId);

                    return this.showInfo(`Accommodatie '${foundPlaceType.name}' is niet beschikbaar met deze zoekvraag.`);
                }

                const placeId = this.$route.query.placeId ? parseInt(this.$route.query.placeId as string, 10) : null;
                const fPlace = this.places.find((place) => place.placeId === placeId);
                const placeType = this.booking.places.find((placeObject) => {
                    return placeObject.placeTypeId === placeTypeId;
                });

                if (!placeType) {
                    this.booking.places.push({
                        placeTypeId,
                        place: fPlace,
                        placeId,
                        preferredPlace: placeId != null ? true : false,
                    });
                }
            });
        }

        if (err) {
            this.clearAndShowError('Mislukt om de beschikbarheid van de accommodatie op te halen.', err);
        }
    }

    public isEnoughCapacity(): boolean {
        const travelGroupAmount = this.booking.travelGroup.reduce((a, b) => a + b.amountOfTravellers, 0);
        const placeCapacity = this.booking.places.reduce((a, b) => a + this.getPlaceCapacity(b), 0);
        return travelGroupAmount <= placeCapacity;
    }

    public getPlaceCapacity(place) {
        const foundPlaceType = this.placeTypes.find((pt) => pt.placeTypeId === place.placeTypeId);
        return foundPlaceType.capacity;
    }

    public getMainBooker(): Booker {
        return new Booker(this.booking.mainBooker);
    }

    public getSelectedPlaceTypes() {
        if (this.booking.places.length) {
            const placeTypes = [];
            this.selectedPlaceTypes.forEach((placeTypeId) => {
                placeTypes.push(this.getPlaceType(placeTypeId));
            });
            return placeTypes;
        }

        return [];
    }

    public addPlaceType(placeTypeId) {
        this.booking.places.push({
            placeTypeId,
            placeId: null,
            preferredPlace: false,
        });
    }

    public removePlace(index: number) {
        this.booking.places.splice(index, 1);
    }

    public getPlaceType(placeTypeId) {
        const placeType = this.availablePlaceTypes.find((type) => type.placeTypeId === placeTypeId);
        return placeType || new PlaceType();
    }

    public isPlaceTypeAvailable(placeTypeId) {
        return !!this.getPlaceType(placeTypeId);
    }

    public getPlaceTypeTitle(placeTypeId) {
        const placeType = this.getPlaceType(placeTypeId);
        return placeType ? placeType.name : '';
    }

    public onPlaceSelected(placeObject: any) {
        this.$nextTick(() => {
            placeObject.placeId = placeObject.place.placeId;
            placeObject.preferredPlace = true;
        });
    }

    public addLicensePlate() {
        this.booking.licensePlates.push(this.newLicensePlate.toUpperCase());
        this.newLicensePlate = '';
    }

    public removeLicensePlate(index) {
        this.booking.licensePlates.splice(index, 1);
    }

    public getTravelType(travelTypeId) {
        const type = this.travelTypes.find((travelType) => {
            return travelType.type === travelTypeId;
        });
        return type;
    }

    public getAllFacets() {
        const facets = [];
        Object.keys(this.facets).forEach((groupKey) => {
            this.facets[groupKey].forEach((facet) => {
                facets.push(facet);
            });
        });

        return facets;
    }

    public getBookedPlaceTypes() {
        const placeTypes = [];
        this.booking.places.forEach((placeObject: BookingPlaceObject) => {
            const placeInfo = { placeType: null, place: null, preferredPlace: null };
            placeInfo.placeType = this.getPlaceType(placeObject.placeTypeId);
            if (placeObject.placeId) {
                placeInfo.place = this.findPlace(placeObject.placeId);
                placeInfo.preferredPlace = placeObject.preferredPlace;
            }
            placeTypes.push(placeInfo);
        });

        return placeTypes;
    }

    public getTotalPriceOfBooking() {
        let total = 0;

        this.getBookedPlaceTypes().forEach((placeObject) => {
            total += placeObject.placeType.price.calculatedPrice;
        });

        return total;
    }

    public setOptionalProducts(products) {
        this.optionalPhysicalProducts = products
            .filter((product) => product.productType === 'PhysicalProduct')
            .map((product) => {
                return {
                    amount: 0,
                    product,
                    productId: product.productId,
                };
            });

        this.optionalOtherProducts = products
            .filter((product) => product.productType !== 'PhysicalProduct')
            .map((product) => {
                return {
                    amount: 1,
                    product,
                    productId: product.productId,
                };
            });
    }

    public getOptionalPhysicalProducts() {
        return this.optionalPhysicalProducts;
    }

    public getOptionalOtherProducts() {
        return this.optionalOtherProducts;
    }

    public isProductSelected(productId: number): boolean {
        return this.selectedOptionalProductIds.indexOf(productId) > -1;
    }

    public toggleSelectedProduct(productId: number, index: number) {
        if (this.isProductSelected(productId)) {
            this.selectedOptionalProductIds.splice(index, 1);
        } else {
            this.selectedOptionalProductIds.push(productId);
        }
    }

    public async getOptionalProductsAsync() {
        const [err, response] = await to(bookingService.getOptionalProductsAsync(this.booking, this.site.siteId));
        if (err || !response) {
            this.clearAndShowError('Mislukt om de opties op te halen.', err);
            return;
        }
        this.setOptionalProducts(response.data);
    }

    public async calculateBookingPrice() {
        if (this.isCalculatingPrice) {
            return;
        }

        this.isCalculatingPrice = true;
        const [err, response] = await to(bookingService.calculateBookingPrice(this.booking, this.site.siteId));
        this.isCalculatingPrice = false;

        if (err || !response) {
            this.clearAndShowError('Mislukt om boeking prijs te berekenen.', err);
            this.booking.priceObject = null;
            this.booking.price = 0;
            return;
        }

        const price = response.data as SearchPrice;
        this.booking.price = price.calculatedPrice;
        this.booking.priceObject = price;
    }

    public validateStepOne() {
        return new Promise((resolve, reject) => {
            if (!this.validateObject('booking.mainBooker')) {
                reject();
            } else {
                this.clearNotifications();
                resolve(true);
            }
        });
    }

    public validateStepTwo() {
        return new Promise((resolve, reject) => {
            if (!this.validateObject('booking.places')) {
                reject();
            } else {
                this.clearNotifications();
                resolve(true);
            }
        });
    }

    public wizardOnChange(prevIndex: number, nextIndex: number) {
        if (nextIndex >= 3) {
            this.calculateBookingPrice();
        }

        if (nextIndex === 2) {
            this.getOptionalProductsAsync();
        }

        if (nextIndex === 3) {
            const otherOptions = [];

            this.selectedOptionalProductIds.forEach((id) => otherOptions.push(this.optionalOtherProducts.find((x) => x.productId === id)));

            const mergedOptions = this.optionalPhysicalProducts.concat(otherOptions);
            this.booking.selectedOptionalProducts = mergedOptions.filter((product) => product.amount > 0);
        }
    }

    public async finishBooking() {
        this.showPending('Boeking aanmaken...');
        const [err, response] = await to(bookingService.addBooking(this.booking, this.site.siteId));
        if (err || !response) {
            this.clearAndShowError('Mislukt om boeking aan te maken.', err);
            return false;
        }

        this.clearAndShowSuccess('Boeking aanmaken is voltooid');
        this.$router.push({ name: 'bookings' });
    }

    private findPlace(placeId): Place {
        return new Place(
            this.places.find((obj) => {
                return obj.placeId === parseInt(placeId, 10);
            }),
        );
    }

    private async loadQueryParams(query) {
        Object.assign(this.searchParams, query);

        this.period.fromDate = query.fromDate ? moment(query.fromDate).toDate() : new Date();
        this.period.toDate = query.toDate ? moment(query.toDate).toDate() : moment(this.period.fromDate).add('1', 'day').toDate();

        if (query.placeTypeIds) {
            this.selectedPlaceTypes = JSON.parse(query.placeTypeIds);
            if (this.selectedPlaceTypes && this.selectedPlaceTypes.length > 0) {
                const placeType = this.placeTypes.find((x) => x.placeTypeId === this.selectedPlaceTypes[0]);
                this.searchParams.toCapacity = placeType.capacity;
            }
        }

        this.searchParams.placeId = +query.placeId;

        if (query.facets) {
            const facetGroups = query.facets.split('&');
            facetGroups.forEach((group) => {
                const parts = group.split('=');
                const name = parts[0];
                const facets = parts[1].split('|');
                this.facets[name] = facets;
            });
        }

        if (query.Senior) {
            const adultTravelType = this.travelTypes.find((type) => {
                return type.travellerType === 'Senior';
            });
            adultTravelType.amount = parseInt(query.Senior, 10);
        }

        if (query.Adult) {
            const adultTravelType = this.travelTypes.find((type) => {
                return type.travellerType === 'Adult';
            });
            adultTravelType.amount = parseInt(query.Adult, 10);
        }

        if (query.ZeroUntilTwoYears) {
            const adultTravelType = this.travelTypes.find((type) => {
                return type.travellerType === 'ZeroUntilTwoYears';
            });
            adultTravelType.amount = parseInt(query.ZeroUntilTwoYears, 10);
        }

        if (query.ChildThreeYearsAndUp) {
            const adultTravelType = this.travelTypes.find((type) => {
                return type.travellerType === 'ChildThreeYearsAndUp';
            });
            adultTravelType.amount = parseInt(query.ChildThreeYearsAndUp, 10);
        }

        if (query.Pet) {
            const adultTravelType = this.travelTypes.find((type) => {
                return type.travellerType === 'Pet';
            });
            adultTravelType.amount = parseInt(query.Pet, 10);
        }

        if (query.mainBookerId) {
            this.booking.mainBooker = this.bookers.find((x) => x.mainBookerId === parseInt(query.mainBookerId, 10));
            if (this.booking.mainBooker) {
                this.booking.mainBookerId = this.booking.mainBooker.mainBookerId;
                await this.getHistoricBookings();
            }
        }

        if (query.place) {
            const [placeTypeId, placeId] = (query.place as string).split('-');
            const place = this.places.find((p: Place) => p.placeId === +placeId);
            this.booking.places.push({
                placeTypeId: +placeTypeId,
                placeId: +placeId,
                preferredPlace: true,
                place,
            });
        }

        if (this.searchParams.disabledAccessible) {
            this.searchParams.disabledAccessible = this.searchParams.disabledAccessible === 'true';
        }

        if (this.searchParams.petsAllowed) {
            this.searchParams.petsAllowed = this.searchParams.petsAllowed === 'true';
        }
    }

    private getTravelTypes(): any[] {
        const types = bookingService.getTravelGroupTypes();

        types.map((travelType) => {
            return (travelType.amount = 0);
        });

        return types;
    }

    private async getBookers(): Promise<Booker[]> {
        const [err, response] = await to(bookerService.getBookers(this.site.siteId));
        if (err) {
            this.showFailedResponse('Mislukt om hoofdboekers op te halen', err);
            return [];
        }

        return (this.bookers = response.data);
    }

    private async getPlaces(): Promise<Place[]> {
        const [err, placesResponse] = await to<AxiosResponse<Place[]>>(siteService.getPlaces(this.site.siteId));
        if (err) {
            this.showError('Mislukt om accommodaties op te halen');
        }
        return placesResponse.data;
    }

    private async getPlaceTypes(): Promise<PlaceType[]> {
        const [err, response] = await to(siteService.getPlaceTypes(this.site.siteId));
        if (err) {
            this.showFailedResponse('Mislukt om accomodatie types op te halen', err);
            return [];
        }
        return (this.placeTypes = response.data);
    }
}
